import React, { useState, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { DateRangePicker } from "@mantine/dates";
import { MultiSelect } from "@mantine/core";

const Filters = styled.div`
  ${tw`flex flex-col items-end justify-center gap-6`}

  .filter {
    ${tw`w-1/2`}
    label, input {
      ${tw`font-din!`}
    }
    .mantine-Input-input {
      ${tw` border-secondary`}
    }
  }
`;

const Button = styled.button(({ disabled }) => [
  tw` font-din w-40 h-12 text-white rounded-md`,
  disabled
    ? tw`bg-gray-400 opacity-50 cursor-not-allowed`
    : tw`bg-secondary hover:scale-105 opacity-100`,
]);

const CLEFilters = ({
  selectedDates,
  setSelectedDates,
  selectedAuthors,
  setSelectedAuthors,
  runSearch,
  authors,
  setPage,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(true);
  const data = authors?.map((author) => ({
    label: author,
    value: author,
  }));

  useEffect(() => {
    setDisabled(!selectedAuthors && !selectedDates);
  }, [selectedDates, selectedAuthors]);
  return (
    <Filters {...rest}>
      <div tw="flex gap-6 w-full">
        <DateRangePicker
          className="filter"
          label="Filter by Date"
          value={selectedDates}
          onChange={setSelectedDates}
        />
        <MultiSelect
          className="filter"
          label="Filter by Speaker(s)"
          data={data}
          value={selectedAuthors}
          onChange={setSelectedAuthors}
          clearable
          searchable
        />
      </div>
      <Button
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          setPage(0);
          runSearch();
        }}
      >
        Filter Results
      </Button>
    </Filters>
  );
};

export default CLEFilters;
