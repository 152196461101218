import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

import Filters from "./CLEFilters";
import Pagination from "./CLEPagination";

const Section = tw.section`flex flex-col relative w-full gap-6`;
const List = tw.ul`flex flex-col gap-8`;
const Single = styled(Link)`
  ${tw`border-secondary rounded-2xl relative items-stretch hover:(scale-105 shadow-2xl border-primary) shadow-xl flex flex-row w-full gap-6 overflow-hidden duration-300 ease-in-out border-2`}
`;
const ImageWrapper = tw.div`min-w-[10rem] max-w-[10rem] [.gatsby-image-wrapper]:h-full [img]:(object-cover) overflow-hidden absolute left-0 top-0 bottom-0 rounded-l-2xl`;
const Author = tw.h3`text-2xl sm:text-3xl font-din`;
const Title = tw.p`mt-1 text-lg sm:(text-xl mt-4 font-bold)`;
const DateComp = tw.span`text-secondary opacity-80 text-sm`;

const Videos = ({ videos, ...rest }) => {
  const [selectedVideos, setSelectedVideos] = useState(videos);
  const [selectedDates, setSelectedDates] = useState([0, new Date()]);
  const [selectedAuthors, setSelectedAuthors] = useState(null);
  const [page, setPage] = useState(0);
  const [paginatedVideos, setPaginatedVideos] = useState([]);
  const videosPerPage = 10;

  // Find all unique authors in the videos object array and add them to a list
  // to be used by the filter
  let authors = [];
  videos.forEach((video) => {
    video.date = new Date(video.date);
    if (video.author && !authors.includes(video.author)) {
      authors.push(video.author);
    }
  });
  authors.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

  // Modify the selected videos state based on current values in the filters
  const runSearch = () => {
    let filteredVideos = videos;
    if (!!selectedAuthors && selectedAuthors.length > 0) {
      filteredVideos = filteredVideos.filter((video) => {
        return selectedAuthors.includes(video.author);
      });
    }
    if (!!selectedDates[0] && !!selectedDates[1]) {
      filteredVideos = filteredVideos.filter(({ date }) => {
        const dateObj = new Date(date);
        return (
          dateObj >= startOfDay(selectedDates[0]) &&
          dateObj <= endOfDay(selectedDates[1])
        );
      });
    }
    setSelectedVideos(filteredVideos);
  };

  const paginate = () => {
    let bin = 0;
    let i = 0;
    let temp = [];
    selectedVideos.forEach((video) => {
      if (i === 0) {
        temp[bin] = [];
        bin++;
      }
      temp[bin - 1].push(video);
      i = (i + 1) % videosPerPage;
    });
    setPaginatedVideos(temp);
  };

  useEffect(() => paginate(), [selectedVideos]);

  return (
    <Section {...rest}>
      <Filters
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        selectedAuthors={selectedAuthors}
        setSelectedAuthors={setSelectedAuthors}
        runSearch={runSearch}
        authors={authors}
        setPage={setPage}
      />
      <List>
        {paginatedVideos.length > 0 &&
          paginatedVideos[page].map(
            ({ title, author, thumbnail, date, link }, i) => (
              <li key={i}>
                <Single to={!!link?.url ? link.url : "#"}>
                  <ImageWrapper>
                    <GatsbyImage
                      image={getImage(thumbnail.localFile)}
                      alt={thumbnail.altTag || author}
                    />
                  </ImageWrapper>
                  <div tw="flex flex-col items-start justify-center pl-44 py-4 pr-4">
                    <Author>{author}</Author>
                    <DateComp>{format(date, "MM/dd/yyyy")}</DateComp>
                    <Title>{title}</Title>
                  </div>
                </Single>
              </li>
            )
          )}
      </List>
      {paginatedVideos?.[0]?.length > 0 ? (
        <Pagination
          tw="self-end"
          page={page}
          setPage={setPage}
          max={paginatedVideos.length - 1}
        />
      ) : (
        <span tw="font-din text-xl">No results found.</span>
      )}
    </Section>
  );
};

export default Videos;
