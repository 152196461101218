/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import InnerTitle from "../components/InnerTitle";
import CLEVideos from "../components/CLEVideos";

const Page = ({ data }) => {
  const { title, content, seo } = data.wpPage;
  const { sidebarMenus } = data.wpPage.sidebarMenuSelect;
  const { videos } = data.wp.acfOptionsCleVideos.cle_videos;

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle>CLE Video Library</InnerTitle>
          <CLEVideos videos={videos} />
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs menus={sidebarMenus} />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
      afterContentForm {
        form: afterContentForm
      }
    }
    wp {
      acfOptionsCleVideos {
        cle_videos {
          videos {
            author
            date
            link {
              ...Button
            }
            thumbnail {
              ...GatsbyImage_Blur
            }
            title
          }
        }
      }
    }
  }
`;
