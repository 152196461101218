import React from "react";
import tw, { styled } from "twin.macro";
import { BsArrowRightSquareFill, BsArrowLeftSquareFill } from "react-icons/bs";
const Pagination = tw.div`flex text-xl font-bold gap-2`;
const Button = styled.button(({ disabled }) => [
  tw`text-bright text-3xl`,
  !!disabled ? tw`text-gray-400` : tw`hover:(text-primary scale-105)`,
]);
const CLEPagination = ({ max, page, setPage, ...rest }) => {
  return (
    <Pagination {...rest}>
      <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
        <BsArrowLeftSquareFill />
      </Button>
      <span>
        {page + 1} / {max + 1}
      </span>
      <Button disabled={page === max} onClick={() => setPage(page + 1)}>
        <BsArrowRightSquareFill />
      </Button>
    </Pagination>
  );
};
export default CLEPagination;
